import React from 'react'

const TermsOfService = () => {
    return (
        <div className=' bg-gray-100 w-full flex justify-center items-center'>
            <div className="bg-white p-8 rounded-lg shadow-md w-[90%] my-8">
                <div className='text-[#333] font-bold text-2xl'>
                    <span>Terms of Services</span>
                </div>
                <div className='mt-4 flex flex-col space-y-4'>
                    <br />
                    <span>A.	This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</span>

                    <span>B.	This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name ayubazar.in (“Website”), including the related mobile site and mobile application (hereinafter referred to as “Platform”).</span>

                    <span>C.	The Platform is owned by Narayani & Co., a company incorporated under the Companies Act, 1956 with its registered office at 01, Ground Floor,
                        Opera Tower,
                        Jawahar Road,
                        Rajkot - 360001 (hereinafter referred to as "Platform Owner", “we”, “us”, “our”).</span>

                    <span>D.	Your use of the Platform and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with the Platform Owner and these terms and conditions including the policies constitute Your binding obligations, with Platform Owner. These Terms of Use relate to your use of our website, goods (as applicable) or services (as applicable) (collectively, “Services”). Any terms and conditions proposed by You which are in addition to or which conflict with these Terms of Use are expressly rejected by the Platform Owner and shall be of no force or effect. These Terms of Use can be modified at any time without assigning any reason. It is your responsibility to periodically review these Terms of Use to stay informed of updates.</span>

                    <span>E.	For the purpose of these Terms of Use, wherever the context so requires "you", “your” or "user" shall mean any natural or legal person who has agreed to become a user/buyer on the Platform.</span>

                    <span>F.	ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.</span>
                    <span>G.	The use of Platform and/or availing of our Services is subject to the following Terms of Use:</span>

                    I.	To access and use the Services, you agree to provide true, accurate and complete information to us during and after registration, and you shall be responsible for all acts done through the use of your registered account on the Platform.
                    II.	Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials offered on this website or through the Services, for any specific purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                    III.	Your use of our Services and the Platform is solely and entirely at your own risk and discretion for which we shall not be liable to you in any manner. You are required to independently assess and ensure that the Services meet your requirements.
                    IV.	The contents of the Platform and the Services are proprietary to us and are licensed to us. You will not have any authority to claim any intellectual property rights, title, or interest in its contents. The contents includes and is not limited to the design, layout, look and graphics.
                    V.	You acknowledge that unauthorized use of the Platform and/or the Services may lead to action against you as per these Terms of Use and/or applicable laws.
                    VI.	You agree to pay us the charges associated with availing the Services.
                    VII.	You agree not to use the Platform and/ or Services for any purpose that is unlawful, illegal or forbidden by these Terms, or Indian or local laws that might apply to you.
                    VIII.	You agree and acknowledge that website and the Services may contain links to other third party websites. On accessing these links, you will be governed by the terms of use, privacy policy and such other policies of such third party websites. These links are provided for your convenience for provide further information.
                    IX.	You understand that upon initiating a transaction for availing the Services you are entering into a legally binding and enforceable contract with the Platform Owner for the Services.
                    X.	You shall indemnify and hold harmless Platform Owner, its affiliates, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys’ fees, made by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use, privacy Policy and other Policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.
                    XI.	In no event will the Platform Owner be liable for any indirect, consequential, incidental, special or punitive damages, including without limitation damages for loss of profits or revenues, business interruption, loss of business opportunities, loss of data or loss of other economic interests, whether in contract, negligence, tort or otherwise, arising from the use of or inability to use the Services, however caused and whether arising in contract, tort, negligence, warranty or otherwise, exceed the amount paid by You for using the Services giving rise to the cause of action or Rupees One Hundred (Rs. 100) whichever is less
                    XII.	Notwithstanding anything contained in these Terms of Use, the parties shall not be liable for any failure to perform an obligation under these Terms if performance is prevented or delayed by a force majeure event.
                    XIII.	These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and construed in accordance with the laws of India.
                    XIV.	All disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Rajkot, Gujarat
                    XV.	All concerns or communications relating to these Terms must be communicated to us using the contact information provided on this website.
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>PURCHASES</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>If you wish to purchase any product or service made available through the Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</span>
                <span>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</span>
                <span>The service may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</span>
                <span>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</span>
                <span>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</span>
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>SHIPPING</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>General Information</span>
                <span>We process and ship orders the same day if your order was placed before 09:00PM IST. Orders placed after 06:00PM IST will be processed and shipped the following business day. Orders will be processed and shipped during regular business hours (Monday through Saturday 9:00AM – 5:00PM IST, excluding holidays).</span>
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>AVAILABILITY, ERRORS AND INACCURACIES</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>We are constantly updating product and service offerings on the Service. We may experience delays in updating information on the Service and in our advertising on other web sites. The information found on the Service may contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced, described inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or completeness of any information found on the Service.</span>
                <span>We therefore reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</span>
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>ACCOUNTS</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.</span>
                <span>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</span>
                <span>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</span>
                <span>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</span>
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>LINKS TO OTHER WEBSITES</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>Our Service may contain links to third party web sites or services that are not owned or controlled by Narayani.</span>
                <span>Narayani. has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</span>
                <span>We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.</span>
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>DISCLAIMER</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</span>
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>EXCLUSIONS</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</span>
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>CHANGES</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. What constitutes a material change will be determined at our sole discretion.</span>
                <span>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</span>
            </div>
            <div className='text-[#333] font-bold text-2xl mt-8'>
                <span>CONTACT US</span>
            </div>
            <div className='mt-4 flex flex-col space-y-4'>
                <span>If you have any questions about these Terms, please contact us via email at: admin@ayubazar.com</span>
            </div>
        </div>
        </div >
    )
}

export default TermsOfService;